<template lang="pug">
  v-container
    //- dialog
    v-dialog( persistent hide-overlay :value='uploadDialog' max-width='800px' transition="dialog-bottom-transition")
      upload-videos(:model ='video' :contentUpload="media" v-if="uploadDialog" @close="uploadDialog=false")
    v-row
      v-col(cols="12").text-center
        v-btn.load-button.mr-0(color="secondary" class="text-uppercase pr-3" rounded @click="takePicture")
          img(class="" src="@/assets/images/icon-btn-1.png")
          span(class="pl-2 ") Open Camera
      v-col(cols="12").text-center
        v-btn.load-button.mr-0(color="secondary" class="text-uppercase pr-3" rounded @click="uploadVideo")
          img(class="" src="@/assets/images/icon-btn-2.png")
          span(class="pl-2 ") Upload Video
    // v-card
      v-card-title
        | What kind of content would you like to capture?
      v-card-text
        v-row
          v-col(cols="12").text-center
            v-btn.load-button.mr-0(color="secondary" class="text-uppercase pr-3" rounded @click="takePicture")
              img(class="" src="@/assets/images/icon-btn-1.png")
              span(class="pl-2 ") Photo
          v-col(cols="12").text-center
            v-btn.load-button.mr-0(color="accentTwo" class="text-uppercase")
              img(class="" src="@/assets/images/icon_video.png")
              span(class="pl-2 ") Video
</template>

<script>
  import { Plugins, CameraResultType } from '@capacitor/core'
  const { Camera } = Plugins

  export default {
    components: {
      UploadVideos: () => import('@/components/custom/dialogs/Videos/UploadsDialog.vue'),
    },
    data () {
      return {
        mediaStream: null,
        media: '',
        uploadDialog: false,
      }
    },

    created () {
      this.takePicture()
    },

    destroyed () {
      const tracks = this.mediaStream.getTracks()
      tracks.map(track => track.stop())
    },

    methods: {
      async takePicture () {
        const vm = this
        const image = await Camera.getPhoto({
          quality: 90,
          allowEditing: true,
          resultType: CameraResultType.Uri,
        })
        const blob = await fetch(image.webPath).then(r => r.blob())
        vm.upload(blob)
      },
      uploadVideo () {
        const vm = this
        const content = {
          type: 'video',
        }
        vm.upload(content)
      },
      async upload (content) {
        const vm = this
        vm.media = content
        vm.uploadDialog = true
      },
    },
  }
</script>
